import GatsbyTemplate from './gatsby-template';

import Simulator from '../../frontend/components/simulator';
import { ResourceBundles } from '../infrastructure/language-service';

export type PageProps = {
  resourceBundles: ResourceBundles;
};

export default GatsbyTemplate<PageProps>(Simulator);
