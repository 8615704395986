import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import IframeResizer from 'iframe-resizer-react';

import Layout from '../common/layout';
import SEO from '../common/seo';

import { initI18nextWithResourceBundles } from '../../utils/i18next-helper';
import { PageProps } from '../../../server/templates/how-to';
import calculatorIcon from '../../images/calculator-solid.svg';
import config from '../../../config';
import { useAppReducer } from './reducer';

type GeoLocationResponse = {
  ipAddress: string;
  country: string;
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const SimulatorPage = ({ resourceBundles }: PageProps): JSX.Element => {
  initI18nextWithResourceBundles(resourceBundles);
  const { t } = useTranslation();
  const [state, dispatch] = useAppReducer();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const fetchGeoLocation = async (): Promise<GeoLocationResponse> => {
          const response = await fetch(config.api.geoLocation);
          return await response.json();
        };
        const geoLocation = await fetchGeoLocation();
        if (geoLocation.country) {
          dispatch({ type: 'SET_COUNTRY', value: geoLocation.country });
        }
      } catch (error) {
        console.error(error);
      }
      dispatch({ type: 'SET_LOADING', value: false });
    };
    fetchData();
  }, []);

  const [iframeSrc, setIframeSrc] = useState('');
  useEffect(() => {
    setIframeSrc(`${config.api.courierCalculator}/?country=${state.country}&shop_url_or_parent_referrer=${document.referrer}`);
  }, [state.country]);

  return (
    <Layout>
      <SEO title="Simulator" />

      <div className="simulator-hero">
        <div className="container">
          <div className={`header`}>
            <img className={`calculatorIcon`} src={calculatorIcon} />
            <h2 i18next-key="simulator-title">{t('simulator-title')}</h2>
          </div>
          <div>{!state.loading && <IframeResizer title={`courier`} src={iframeSrc} frameBorder={0} style={{ width: '1px', minWidth: '100%' }} />}</div>
        </div>
      </div>
    </Layout>
  );
};

export default SimulatorPage;
