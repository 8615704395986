import { useReducer } from 'react';

export type State = {
  loading: boolean;
  country: string;
};
export const initialState: State = {
  loading: true,
  country: ''
};

export type ActionSetCountry = { type: 'SET_COUNTRY'; value: string };
export type ActionSetLoading = { type: 'SET_LOADING'; value: boolean };
export type Action = ActionSetCountry | ActionSetLoading;

const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case 'SET_COUNTRY':
      return { ...state, country: action.value };
    case 'SET_LOADING':
      return { ...state, loading: action.value };
    default:
      throw new Error('Invalid action type!');
  }
};

export const useAppReducer = () => {
  return useReducer(reducer, initialState);
};
